import { useState } from "react"
import './TheEnd.css'

export default function TheEnd() {
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')

    function handleNameChange(event) {
        setValue1(event.target.value)
    }

    function handleEmailChange(event) {
        setValue2(event.target.value)
    }
    

    function handleClick() {
        console.log([value1, value2])
    }

    const handleEnter = (value) => {
        if (value.key === 'Enter') 
            handleClick()
        else handleClick()
    }


    return(
        <div>
            <div>{handleClick() && <p>ddddd</p> }</div>

            <div class="container">
                <div class="row">
                    <div className="titleEnd col align-self-start">
                        <span>Мы нашли <strong style={{color: 'rgb(64,224,208)'}}><br />более 15 возможных</strong><br /> вариантов для вас!<br />
                            Оставьте свои <br /> контактные данные <br /> для того,
                            чтобы мы могли <br /> связаться с Вами.</span>
                    </div>

                    <div class="col align-self-center">
                        <form className="form">
                            <div className="nameForm mb-3">
                                <div className="titleForm"><strong>Ваши данные    </strong></div>
                                <input className="formName" type="text" value={value1} 
                                onChange={handleNameChange} placeholder="Ваше имя" onKeyUp={handleEnter} required/>
                            </div>

                            <div class="mb-3">
                                <input type="email" value={value2} className="formName" onChange={handleEmailChange} 
                                placeholder="Ваша почта" onKeyUp={handleEnter} required/>
                            </div>
                            <input type="submit" className="submit" value={"Oтправить"} onClick={handleEnter}/>
                    
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

