import React from 'react'
import './Button.css'

export default function Button() {
    function scrollToSelection() {
        document.getElementById('targetElement').scrollIntoView()
    }

    return(
        <div onClick={scrollToSelection}>ПОЛУЧИТЬ ПОДБОРКУ</div>
    )
}