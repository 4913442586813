import React from "react";
import { useState } from "react";
import Question from "./Question";
import Bottom from "../bottom/bottom";
import './Questions.css'
import TheEnd from "./TheEnd";



export default function Questionary() {

    const questionary = {
        1: ['Студия', '1-комнатная квартира', 
        '2-х комнатная квартира',
        '3-х комнатная квартира',
        'Смотрю различные варианты'],

        2: ['Старт продаж', 
        'Сдача дома в этом году', 
        'Сдача дома в следующем году',
        'Смотрю различные варианты'],

        3: ['Низкий этаж', 
        'Средний этаж',
        'Высокий этаж', 
        'Смотрю различные варианты'],

        4: ['Смотрю различные варианты', 
        'Пионерский','Пышма','Солнечный',
        'Сортировка', 'Чкаловский', 'Уралмаш', 
        'Широкая речка', 'Эльмаш', 'Орджоникидзевский', 
        'Октябрьский', 'Кировский', 'Железнодорожный', 
        'Верх-Исетский', 'Академический', 'ВИЗ', 'Ленинский'],

        5: ['Без отделки', 'Предчистовая отделка (White Box)', 
        'С отделкой', 'Дизайнерский ремонт', 
        'Не имеет значения', 'Смотрю различные варианты'],
    };

    const roster = ['Какую квартиру подбираете?',
    'Какой этап строительства рассматриваете?',
    'На каком этаже вы хотели бы квартиру?',
    'Какой район рассматриваете?', 'Желаемая отделка']
    const [indexR, setIndexR] = useState(0)

    const [counter, setCounter] = useState(1)

    const [index, setIndex] = useState(1);
    const [answers, setAnswers] = useState([])

    function DelAnswer(reply) {
        answers.pop(reply)
        setAnswers(answers)
        console.log(answers)
        setIndex(index - 1)
        setIndexR(indexR-1)
        setCounter(counter - 1)
    }

    function SetAnswer(answer) {
        answers.push(answer)
        setAnswers(answers)
        console.log(answers)

    if (questionary.hasOwnProperty(index + 1)) {
        setIndex(index + 1)
        setIndexR(indexR + 1) 
        setCounter(counter + 1) }
    else {
        // setIndexR(0)
        // setIndex(1) 
        setCounter(counter + 1)
        // Добавить после этого функцию отправки сообщения
        answers.length = 0;
        console.log(answers)
    }
}

    return (
        <div>
            

            <div>{counter < 6 && 
                <div className='middleSurvey'>
                    <span className="titleMiddle">{roster[indexR]}</span> 

                    <div className="paddingMiddle"></div>

                    {questionary[index].map((question) => (
                        <Question value={question} handleClick={SetAnswer} />
                    ))}

                    <hr />

                    <div className="bottom">
                        <div class='row'>
                            <div className='col_3 col'>{counter !== 1 && <Bottom handleClick={DelAnswer}/>}</div>
                            <div className='col_3 col'>{counter === 1 && <div style={{padding: '30px', minWidth: '500%'}}></div>}</div>
                            <div className='col_3 col'><span className="counter">{counter}/5</span></div>
                        </div>
                    </div>
                </div>
            }</div>

            <div>{counter === 6 && <TheEnd />}</div>
        </div>
    )
}