import React from "react";
import './Header.css'
import Button from "../selection/Button";
// import background from '.images/backgroundHeader.jpg'

export default function Header() {
    return(
        <div className="header">
            <div style={{position:'relative', zIndex:'2'}}>
            <div className="texttitle">Подберите недвижимость <br />
                    в <strong>Екатеринбурге</strong><br />
                    <span style={{color: 'rgb(64, 224, 208)'}}>за минуту онлайн</span></div>


            <div class="header_menu">
                <div class="row align-items-start">

                    {/* первая колонка */}
                    <div class="col">
                        <div className="col_header">
                            <ul className="menu_1">
                                <li>От <strong>17 000 ₽/мес</strong> платежи по ипотеке</li>
                                <li>Цена квартиры от 3,5 млн ₽</li>
                            </ul>

                            <ul className="menu_2">
                                <li className="menuOffer">
                                    <span style={{color: 'rgb(64, 224, 208)'}}>
                                    <strong>Ответьте на 5 вопросов и получите подборку </strong></span>
                                    подходящих для вас вариантов<br />
                                    <u>на выгодных условиях</u></li>

                                <li style={{padding: '44px 0px 0px 0px'}}>
                                    <div className="button"><Button /></div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* втора колонка */}
                    <div class="col">
                        <div className="col_2 w-50" >
                            <ul>
                                <li><strong className="top">0% комиссия</strong><br />
                                    <span className="span_2">
                                    За вас платит <br />
                                    застройщик</span></li>

                                <li><strong className="top">99%</strong><br />
                                <span className="span_2">
                                Вероятность<br />одобрения ипотеки</span></li>

                                <li><strong className="top">90+</strong><br />
                                <span className="span_2">
                                Надежных жилых<br />комплексов</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}