import './selection.css'
import React from 'react'
import Button from './Button'
import semia from './images/semia.jpg'
import start from './images/start.jpg'
import mortgage from './images/ipoteka.jpg'
import exchange from './images/exchange.jpg'

export default function Selection() {
    return (
        <div class="container">
            <div class="row">
                <div class="col">
                    <div className='col_1'>
                        <img src={start} className='images' alt=''></img>
                        <p className='titletext'><strong>На старте<br />продаж</strong></p>
                        <p className='text'>Самые <strong>выгодные <br />
                        варианты</strong> и цены <br />за кв. метр</p>
                        <div className='button_3'><Button  /></div>
                    </div>
                </div>
                <div class="col">
                    <div className='col_1'>
                        <img src={semia} className='images' alt=''></img>
                        <p className='titletext'><strong>Для семей с<br />детьми</strong></p>
                        <p className='text'>Используйте <br /><strong>материнсĸий<br />
                        ĸапитал</strong> и<br />получите ставĸу по <br />ипотеĸе от 4%</p>
                        <div className='button_3'><Button  /></div>
                    </div>
                </div>
                <div class="col">
                    <div className='col_1'>
                        <img src={mortgage} className='images' alt=''></img>
                        <p className='titletext'><strong>Траншевая<br />ипотека</strong></p>
                        <div className='paddingSelection' />
                        <p className='text'>Платите <strong>1 ₽/мес.</strong><br />до сдачи дома</p>
                        <div className='button_3'><Button  /></div>
                    </div>
                </div>

                <div class="col">
                    <div className='col_1'>
                        <img src={exchange} className='images' alt=''></img>
                        <p className='titletext'><strong>Обмен вторички<br />на новостройку</strong></p>
                        <p className='text'>С проживанием <strong>в<br />своей квартире</strong>
                        <br />до сдачи новой</p>
                        <div className='button_3'><Button  /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}