import './App.css';
import TopSurvey from './components/TopSurvey';
import Questionary from './components/Questions';
import Selection from './selection/selection';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header/Header';
import Footer from './footer/Footer';



export default function App() {
  return (
    <div style={{minWidth: '660px'}}>
      <Header />

      <p className='titleSurvey'>Получите <strong style={{color: 'rgb(64, 224, 208)'}}>подборку свободных квартир</strong><br />
          и цены на них прямо сейчас</p>

      <div className='middle'>
        <div className='survey'>
          <TopSurvey />
          <hr />
          <Questionary/>
        </div>
      </div>
      
      <p className='titleSurvey'>Получите уже <strong style={{color: 'rgb(64, 224, 208)'}}>готовую подборку</strong> квартир<br />
с ценами и планировками под разные цели</p>

      <Selection/>

      <Footer />
    </div>
  );
}
