import React from "react";
import './Footer.css'
import Button from "../selection/Button";

export default function Footer() {
    return(
        <div className="footer">
            <div style={{position:'relative', zIndex:'2'}}>
                <div className="info_label">
                    <div className="title">Подборка лучших 
                        <span style={{color: "rgb(64,224,208)"}}><strong> акций месяца в</strong></span><br />
                        новостройках <u>Екатеринбурга</u> с выгодой до<br />
                        <span style={{color: 'rgb(64,224,208)'}}><strong>1 миллиона рублей</strong></span></div>

                    <div className="info">Посмотрите варианты из закрытой риэлторской базы, которые мы <br />
                        подготовили для вас</div>

                    <div className='button_2'><Button /></div>
                </div>
            </div>
        </div>
    )
}